<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 20 13.482">
    >
    <path
      :style="iconStyle"
      d="M11.5,4.922a10.753,10.753,0,0,0-10,6.741,10.788,10.788,0,0,0,20,0A10.753,10.753,0,0,0,11.5,4.922Zm0,11.237a4.5,4.5,0,1,1,4.545-4.5A4.533,4.533,0,0,1,11.5,16.158Zm0-7.192a2.7,2.7,0,1,0,2.728,2.7A2.721,2.721,0,0,0,11.5,8.967Z"
      transform="translate(-1.5 -4.922)"
    />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '13.482',
    },
    width: {
      type: String,
      default: '20',
    },
    color: {
      type: String,
      default: 'menu',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
